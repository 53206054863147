import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiClient } from "app/configs/apiClient";

const initialState = {
  selectedLocation: "",
  institutionGatewayDetailId: null,
  allBusinessess: true,
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    getLocations(state, action) {
        state.selectedLocation = action.payload.selectedLocation,
        state.institutionGatewayDetailId = action.payload.institutionGatewayDetailId,
        state.allBusinessess = action.payload.allBusinessess
    },
  },
});

export const { getLocations } = locationSlice.actions;
export default locationSlice.reducer;
