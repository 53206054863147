import { lazy } from 'react';

const Products = lazy(()=>import('./Products/products'));
const Product = lazy(()=>import('./Product/product'));

const ConversationConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'conversations',
      element: <Products />,
      children: [
        {
          path: ':id',
          element: <Product />,
        },
      ],
    },
  ],
}

export default ConversationConfig



