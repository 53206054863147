
/* eslint-disable prettier/prettier */
import i18next from "i18next";
import { User_Roles } from "../globalConstants";
import ar from "./navigation-i18n/ar";
import en from "./navigation-i18n/en";

i18next.addResourceBundle("en", "navigation", en);
i18next.addResourceBundle("ar", "navigation", ar);

export const cpasshost = [
  "cpass.spiolabs.com",
  "cpdev.spiolabs.com"];

export const portalhost = ["portal.spiolabs.com", "dev.spiolabs.com",];
export const faceIdhost = ["face.spiolabs.com ", "facedev.spiolabs.com",];
export const connecthost = ["con.spiolabs.com", "condev.spiolabs.com", "localhost"];

const navigationConfig = [
  // {
  //   id: "dash",
  //   title: "Dashboard",
  //   translate: "DASH",
  //   type: "item",
  //   icon: "material-solid:space_dashboard",
  //   url: "dash",
  //   host: cpasshost,
  // },
  // {
  //   id: "dsh",
  //   title: "Dashboard",
  //   translate: "DSH",
  //   type: "item",
  //   icon: "material-solid:space_dashboard",
  //   url: "faceid_dashboard",
  //   host: faceIdhost,
  // },
  {
    id: "dashboard_connect",
    title: "Dashboard",
    translate: "DS",
    type: "item",
    icon: "material-solid:space_dashboard",
    url: "connect_dashboard",
    host: connecthost,
  },
  // {
  //   id: "reviews",
  //   title: "Reviews",
  //   translate: "REVIEWS",
  //   type: "item",
  //   icon: "material-outline:reviews",
  //   url: "views",
  //   host: cpasshost,
  // },
  // {
  //   id: "get",
  //   title: "Get Reviews",
  //   translate: "GET_REVIEWS",
  //   type: "item",
  //   icon: "material-solid:review",
  //   url: "reviews",
  //   host: cpasshost,
  // },
  // {
  //   id: "review link",
  //   title: "Review Link",
  //   translate: "REVIEW_LINK",
  //   type: "item",
  //   icon: "material-solid:link2",
  //   url: "review_link",
  //   host: cpasshost,
  // },
  // {
  //   id: "reports-of-spio",
  //   title: "Analytics_reports",
  //   type: "collapse",
  //   icon: "material-solid:analytics",
  //   translate: "ANALYTICS_REPORTS",
  //   host: cpasshost,
  //   children: [
  //     {
  //       id: "Reviews&Ratings",
  //       title: "Reviews&Ratings",
  //       type: "item",
  //       translate: "REVIEWS_AND_RATINGS",
  //       url: "reviews&ratings",
  //       host: cpasshost,
  //     },
  //     {
  //       id: "location-wise-graph",
  //       title: "LocationWiseGraph",
  //       type: "item",
  //       translate: "LOCATION_WISE_GRAPH",
  //       url: "location_wise_graph",
  //       host: cpasshost,
  //     },
  //     {
  //       id: "reviews-distribution-by-employee",
  //       title: "ReviewsDistributionByEmployee",
  //       type: "item",
  //       translate: "REVIEWS_DISTRIBUTION_BY_EMPLOYEE",
  //       url: "reviews_distribution_by_employee",
  //       host: cpasshost,
  //     },
  //     {
  //       id: "reponsive-time-over-time",
  //       title: "ResponsiveTimeOverTine",
  //       type: "item",
  //       translate: "RESPONSIVE_TIME_OVER_TIME",
  //       url: "responsive_time_over_time",
  //       host: cpasshost,
  //     },
  //     {
  //       id: "response-time-distribution",
  //       title: "ResponseTimeDistribution",
  //       type: "item",
  //       translate: "RESPONSE_TIME_DISTRIBUTION",
  //       url: "response_time_distribution",
  //       host: cpasshost,
  //     },
  //     {
  //       id: "leaderboard",
  //       title: "Leaderboard",
  //       type: "item",
  //       translate: "LEADERBOARD",
  //       url: "leaderboard",
  //       host: cpasshost,
  //     },
  //     {
  //       id: "response-rate-by-city",
  //       title: "ResponseRateByCity",
  //       type: "item",
  //       translate: "RESPONSE_RATE_BY_CITY",
  //       url: "response_rate_by_city",
  //       host: cpasshost,
  //     },
  //     {
  //       id: "review-nps-by-location",
  //       title: "ReviewNPSbyLoaction",
  //       type: "item",
  //       translate: "REVIEW_NPS_BY_LOCATION",
  //       url: "reviews_nps_by_location",
  //       host: cpasshost,
  //     },
  //   ]
  // },
  {
    id: "agents",
    title: "Agents",
    translate: "AGENTS",
    type: "item",
    icon: "material-solid:support_agent",
    url: "agents",
    host: connecthost,
  },
  {
    id: "knowledge_base",
    title: "Knowledge_Base",
    translate: "KNOWLEDGE_BASE",
    type: "item",
    icon: "material-solid:auto_stories",
    url: "knowledge_base",
    host: connecthost,
  },
  {
    id: "conversations",
    title: "Conversations",
    translate: "CONVERSATIONS",
    type: "item",
    icon: "material-solid:question_answer",
    url: "conversations",
    host: connecthost,
  },
  // {
  //   id: "automate",
  //   title: "Automate",
  //   translate: "AUTOMATE",
  //   type: "item",
  //   icon: "material-solid:automate",
  //   url: "automate",
  //   host: cpasshost,
  //   hide: [0]
  // },
  // {
  //   id: "faceid_analytics",
  //   title: "Analytics_FaceID",
  //   type: "item",
  //   icon: "material-solid:analytics",
  //   translate: "ANALYTICS_FACEID",
  //   host: faceIdhost,
  // },
  {
    id: "connect_analytics",
    title: "Analytics_Connect",
    type: "collapse",
    icon: "material-solid:analytics",
    translate: "ANALYTICS_CONNECT",
    host: connecthost,
    children: [
      {
        id: "live cdr",
        title: "Live CDR",
        translate: "LIVE_CDR",
        type: "item",
        url: "live_cdr",
        host: connecthost,
      },
    ]
  },
  // {
  //   id: "connectuser-Database",
  //   title: "User Database",
  //   translate: "CONNECT_USER_DATABASE",
  //   type: "collapse",
  //   icon: "heroicons-outline:user-group",
  //   host: faceIdhost,
  //   children: [
  //     {
  //       id: "registered-users",
  //       title: "Registered Users",
  //       translate: "REGISTERED_USERS",
  //       type: "item",
  //       url: "registered_users",
  //     },
  //     {
  //       id: "temporary-users",
  //       title: "Unverified Users",
  //       translate: "TEMPORARY_USERS",
  //       type: "item",
  //       url: "temporary_users",
  //     },
  //   ],
  // },
  // Connect Settings
  {
    id: "connect_settings",
    title: "Settings",
    translate: "SETTINGS",
    type: "collapse",
    icon: "material-solid:settings_suggest",
    host: connecthost,
    children: [
      {
        id: "did-master",
        title: "DID Master",
        translate: "DID_MASTER",
        type: "item",
        url: "did_master",
      },
      {
        id: "virtual-no-mapping",
        title: "Virtual No Mapping",
        translate: "VIRTUAL_NO_MAPPING",
        type: "item",
        url: "virtualNoMappingCopy",
      },
      {
        id: "general-settings",
        title: "General Settings",
        translate: "GENERAL_SETTINGS",
        type: "collapse",
        children: [
          {
            id: "administrators",
            title: "Administrators",
            translate: "ADMINISTRATORS",
            type: "item",
            url: "administrators",
          },
          {
            id: "license-billing",
            title: "Billing details",
            translate: "BILLING_DETAILS",
            type: "item",
            url: "billing_details",
          },
          {
            id: "branches",
            title: "Branches",
            translate: "BRANCHES",
            type: "item",
            url: "branch",
          },
          {
            id: "account-information",
            title: "Account Information",
            translate: "LOCATION_INFORMATION",
            type: "item",
            url: "account_information",
          },
        ]
      },
    ]
  },
  // Review Settings
  // {
  //   id: "review_settings",
  //   title: "Settings",
  //   translate: "SETTINGS",
  //   type: "collapse",
  //   icon: "material-solid:settings_suggest",
  //   host: cpasshost,
  //   children: [
  //     {
  //       id: "employee-master",
  //       title: "Employee Master",
  //       translate: "EMPLOYEE_MASTER",
  //       type: "item",
  //       url: "employee_master",
  //     },
  //     {
  //       id: "location",
  //       title: "Location",
  //       translate: "LOCATION",
  //       type: "item",
  //       url: "review_gateways_settings",
  //     },
  //     {
  //       id: "general-settings",
  //       title: "General Settings",
  //       translate: "GENERAL_SETTINGS",
  //       type: "collapse",
  //       children: [
  //         {
  //           id: "administrators",
  //           title: "Administrators",
  //           translate: "ADMINISTRATORS",
  //           type: "item",
  //           url: "administrators",
  //         },
  //         {
  //           id: "license-billing",
  //           title: "Billing details",
  //           translate: "BILLING_DETAILS",
  //           type: "item",
  //           url: "billing_details",
  //         },
  //         {
  //           id: "branches",
  //           title: "Branches",
  //           translate: "BRANCHES",
  //           type: "item",
  //           url: "branch",
  //         },
  //         {
  //           id: "account-information",
  //           title: "Account Information",
  //           translate: "LOCATION_INFORMATION",
  //           type: "item",
  //           url: "account_information",
  //         },
  //       ]
  //     },
  //     {
  //       id: "templetes",
  //       title: "Templates",
  //       translate: "TEMPLATES",
  //       type: "item",
  //       url: "templates",
  //     },
  //     {
  //       id: "social",
  //       title: "Social",
  //       translate: "SOCIAL",
  //       type: "item",
  //       url: "social",
  //     },
  //     {
  //       id: "custom",
  //       title: "Custom",
  //       translate: "CUSTOM",
  //       type: "item",
  //       url: "custom",
  //       host: cpasshost,
  //     },
  //     {
  //       id: "integration",
  //       title: "Integration",
  //       translate: "INTEGRATION",
  //       type: "item",
  //       url: "integration",
  //     },
  //   ]
  // },
  // FaceID Settings
  // {
  //   id: "faceid_settings",
  //   title: "Settings",
  //   translate: "SETTINGS",
  //   type: "collapse",
  //   icon: "material-solid:settings_suggest",
  //   host: faceIdhost,
  //   children: [
  //     {
  //       id: "general-settings",
  //       title: "General Settings",
  //       translate: "GENERAL_SETTINGS",
  //       type: "collapse",
  //       children: [
  //         {
  //           id: "administrators",
  //           title: "Administrators",
  //           translate: "ADMINISTRATORS",
  //           type: "item",
  //           url: "administrators",
  //         },
  //         {
  //           id: "license-billing",
  //           title: "Billing details",
  //           translate: "BILLING_DETAILS",
  //           type: "item",
  //           url: "billing_details",
  //         },
  //         {
  //           id: "branches",
  //           title: "Branches",
  //           translate: "BRANCHES",
  //           type: "item",
  //           url: "branch",
  //         },
  //         {
  //           id: "account-information",
  //           title: "Account Information",
  //           translate: "LOCATION_INFORMATION",
  //           type: "item",
  //           url: "account_information",
  //         },
  //       ]
  //     },
  //   ]
  // },
  // {
  //   id: "processing",
  //   title: "Processing",
  //   translate: "PROCESSING",
  //   type: "item",
  //   icon: "material-solid:space_dashboard",
  //   url: "processing",
  //   host: faceIdhost,
  // },
  // {
  //   id: "configuration",
  //   title: "Configuration",
  //   translate: "CONFIGURATION",
  //   type: "item",
  //   icon: "material-solid:perm_data_setting",
  //   url: "configuration",
  //   host: faceIdhost,
  // },
  // {
  //   id: "notification",
  //   title: "Notification",
  //   translate: "NOTIFICATION",
  //   type: "item",
  //   icon: "material-solid:notifications_none",
  //   url: "notification",
  //   host: faceIdhost,
  // },
  // {
  //   id: "review_logout",
  //   title: "Logout",
  //   translate: "LOGOUT",
  //   type: "item",
  //   icon: "material-outline:logout",
  //   url: "sign-out",
  //   host: cpasshost,
  // },
  // {
  //   id: "faceid_logout",
  //   title: "Logout",
  //   translate: "LOGOUT",
  //   type: "item",
  //   icon: "material-outline:logout",
  //   url: "sign-out",
  //   host: faceIdhost,
  // },
  {
    id: "connect_logout",
    title: "Logout",
    translate: "LOGOUT",
    type: "item",
    icon: "material-outline:logout",
    url: "sign-out",
    host: connecthost,
  },
];

export default navigationConfig;

