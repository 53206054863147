import { lazy } from 'react';

const Product = lazy(()=>import('./Product/product'));

const AgentsConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'agents',
      element: <Product />,
    },
  ],
}

export default AgentsConfig



